import React, { useState, useEffect, useRef } from "react";
import $ from 'classnames';
import { apricot4 } from '@widgets/core';
import debounce from 'lodash/debounce';
import { levity, levityStore } from '@widgets/core';
import styles from './styles.module.scss';
var MigrationNotice = function () {
    var _a;
    var gridClass3 = "col-md-offset-1 col-md-10 col-lg-offset-2 col-lg-8";
    var gridClass4 = "offset-md-1 col-md-10 offset-lg-2 col-lg-8 offset-xl-2 col-xl-8 offset-2xl-3 col-2xl-6 offset-3xl-3 col-3xl-6";
    var gridClass = apricot4 ? gridClass4 : gridClass3;
    var containerRef = useRef();
    var _b = useState(125), height = _b[0], setHeight = _b[1];
    var _c = useState(false), showNotice = _c[0], setShowNotice = _c[1];
    var checkMigration = function () {
        var token = (typeof levity.getJWTToken === 'function') ? levity.getJWTToken() : null;
        var isMigrated = true;
        if (levityStore.isLoggedIn && token && levityStore.userType == 'student') {
            var parsedToken = JSON.parse(window.atob(token.split('.')[1]));
            if (parsedToken.cb && !parsedToken.cb.oktaAccountId) {
                isMigrated = false;
            }
        }
        return isMigrated;
    };
    var style = { height: height + 'px' };
    useEffect(function () {
        setTimeout(function () { updateViewportHeight(); }, 1000);
        setTimeout(function () {
            if (!checkMigration()) {
                setShowNotice(true);
            }
        }, 500);
        window.addEventListener('resize', debounce(function () {
            if (containerRef.current) {
                updateViewportHeight();
            }
        }, 350));
    }, []);
    var updateViewportHeight = function () {
        if (containerRef.current) {
            var h = containerRef.current.getBoundingClientRect().height + 48;
            setHeight(h);
        }
    };
    return ((levityStore.isLoggedIn && showNotice) &&
        (React.createElement("div", { id: 'notification-st-migration', className: $('cb-widget', styles.root), style: style },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: gridClass },
                        React.createElement("div", { className: styles.notifications },
                            React.createElement("div", { ref: containerRef, className: styles.notification },
                                React.createElement("div", { className: styles.viewport },
                                    React.createElement("div", { className: $(styles.icon, (_a = {}, _a["" + styles.apricot3] = !apricot4, _a)) },
                                        React.createElement("i", { className: "cb-glyph cb-glyph-circular cb-megaphone", "aria-hidden": "true" })),
                                    React.createElement("div", { className: styles.message },
                                        React.createElement("p", null,
                                            React.createElement("strong", null, "IMPORTANT"),
                                            ": Your College Board account update is incomplete. If you are taking a digital AP Exam in school this year, you must ",
                                            React.createElement("a", { href: "https://account.collegeboard.org/login/login?flow=idp-discovery", "data-cbtrack-promo": "migration-reminder" }, "click here to finish updating your account"),
                                            " as soon as possible to be able to access your exam.")),
                                    React.createElement("div", { className: styles.control },
                                        React.createElement("button", { onClick: function (e) { return setHeight(0); } },
                                            React.createElement("i", { className: "cb-glyph cb-x-mark" },
                                                React.createElement("span", { className: "sr-only" }, "Close Notification")))))))))))));
};
export default MigrationNotice;
