var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component, Fragment } from 'react';
import $ from 'classnames';
import { globalStore, viewport, levityStore } from '@widgets/core';
import { PersonalizedIdentityWidget } from '@widgets/identity';
import NavigationLinks from './NavigationLinks';
import Panel from './Panel';
import styles from './styles.module.scss';
import MenuControl from './MenuControl';
import { GlobalHeaderSearch } from '@widgets/search';
import Overlay from './Overlay';
import { autorun } from 'mobx';
import LoginAndSearchControls from './LoginAndSearchControls';
import strings from './l10n';
import { merge } from 'lodash';
import { apricot4 } from '@widgets/core';
import MigrationNotice from './MigrationNotice';
// Collegeboard logo linked to cb.org + site-specific
// logo as glyph or text, linked to site homepage.
var LogoLockupView = function (p) {
    var _a, _b, _c;
    var title = p.title, useGlobalNavigation = p.useGlobalNavigation, useGlyphLogo = p.useGlyphLogo;
    var logo = (title || '').replace(/ |-|\//g, '-').toLowerCase();
    return (React.createElement("div", { className: $((_a = {},
            _a["" + styles.items] = true,
            _a["" + styles.lockup] = true,
            _a["" + styles.useGlobalNavigation] = useGlobalNavigation,
            _a)) },
        React.createElement("h1", { role: "presentation" },
            React.createElement("a", { href: "https://www.collegeboard.org/", className: $(styles.logo, 'cb-glyph-logo cb-logo'), "data-cbtrack-linktype": "nav" },
                React.createElement("span", { className: "sr-only" }, "The College Board"))),
        title &&
            React.createElement("h2", { role: "presentation", "data-cbtrack-linkgroup": "logo-lockup", className: styles.logoLockup },
                React.createElement("a", { "aria-label": title, href: "/", "data-cbtrack-linktype": "nav", className: $(styles.program, (_b = {}, _b[styles.glyphLogo + " " + styles[logo]] = useGlyphLogo, _b), (_c = {}, _c["cb-glyph-logo cb-programs-" + logo] = useGlyphLogo, _c)) }, useGlyphLogo
                    ? (React.createElement(Fragment, null,
                        React.createElement("span", { className: "sr-only" }, title)))
                    : (React.createElement(Fragment, null, title))))));
};
// Left side of header- adds global nav trigger to logolockupview.
var WayfindingView = function (p) {
    var title = p.title, programType = p.programType, activePanel = p.activePanel, togglePanel = p.togglePanel, useGlyphLogo = p.useGlyphLogo, useGlobalNavigation = p.useGlobalNavigation, menuRef = p.menuRef;
    return (React.createElement("div", { className: $(styles.items, styles.noshrink) },
        useGlobalNavigation &&
            React.createElement(MenuControl, { controls: "global-header-navigation", label: strings.globalNavigation, programType: programType, activePanel: activePanel, togglePanel: togglePanel, ref: menuRef }),
        React.createElement(LogoLockupView, { useGlobalNavigation: useGlobalNavigation, useGlyphLogo: useGlyphLogo, title: title })));
};
// Marries LoginAndSearchControls to WayfindingView and
// flyouts for login/nav to render complete header
var Header = /** @class */ (function (_super) {
    __extends(Header, _super);
    function Header() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.root = React.createRef();
        _this.controls = React.createRef();
        _this.menu = React.createRef();
        _this.state = { activePanel: null };
        _this.navPanel = React.createRef();
        _this.loginPanel = React.createRef();
        _this.disposer = null;
        return _this;
    }
    Header.prototype.togglePanel = function (panelName) {
        this.setState({ activePanel: this.state.activePanel === panelName ? null : panelName }); // This is the clean short hand
    };
    Header.prototype.render = function () {
        var _a;
        var _this = this;
        var activePanel = this.state.activePanel;
        var _b = this.props, appId = _b.appId, programType = _b.programType, title = _b.title, skipLocation = _b.skipLocation, useGlyphLogo = _b.useGlyphLogo, useGlobalNavigation = _b.useGlobalNavigation, useSearchWidget = _b.useSearchWidget, useLoginWidget = _b.useLoginWidget, identityConfig = _b.identityConfig, searchConfig = _b.searchConfig, destinationUrl = _b.destinationUrl, idp = _b.idp;
        var ghSearchConfig = {
            appId: appId,
            additionalParams: {
                'siteType': programType === 'corporate' ? 'default' : programType
            }
        };
        var ghIdentityConfig = {
            appId: appId
        };
        var finalSearchConfig = merge(ghSearchConfig, searchConfig);
        var finalIdentityConfig = merge(ghIdentityConfig, identityConfig);
        var authEndpointParam = (finalIdentityConfig.endpointParams && finalIdentityConfig.endpointParams.authentication) ? finalIdentityConfig.endpointParams.authentication : null;
        var closePanel = function () { return _this.togglePanel(null); };
        var focusCloseIcon = function (e) {
            e.preventDefault();
            _this.controls.current.focusOnCloseIcon();
        };
        return (React.createElement(Fragment, null,
            activePanel && React.createElement(Overlay, { onClick: closePanel }),
            React.createElement("div", { id: this.props.tracker.id, ref: this.root, role: "banner", tabIndex: -1, onKeyDown: function (e) {
                    if (activePanel === 'links') {
                        var tRef = e.target.getAttribute('href');
                        var mRef = _this.menu.current.anchorRef.current.getAttribute('href');
                        var links = _this.navPanel.current.ref.current.getElementsByTagName('a');
                        if (e.key === 'Tab' && e.shiftKey) {
                            if (tRef === links[0].href) {
                                e.preventDefault();
                                _this.menu.current.focus();
                                _this.togglePanel('links');
                            }
                        }
                        else if (e.key === 'Tab') {
                            if (tRef === mRef) {
                                e.preventDefault();
                                if (links.length) {
                                    links[0].focus();
                                }
                            }
                            if (links[links.length - 1].getAttribute('href') === tRef) {
                                e.preventDefault();
                                _this.menu.current.focus();
                            }
                        }
                    }
                }, onKeyUp: function (e) {
                    if (activePanel) {
                        if (e.key === 'Escape') {
                            if (activePanel === 'links') {
                                _this.togglePanel('links');
                                _this.menu.current.focus();
                            }
                            if (activePanel === 'login') {
                                _this.togglePanel(activePanel);
                                _this.controls.current.focusOnLoginControl();
                            }
                            if (activePanel === 'search') {
                                _this.togglePanel(activePanel);
                                _this.controls.current.focusOnSearchControl();
                            }
                        }
                    }
                }, "data-cbtrack-widget": "header", className: $('cb-widget', styles.root, styles[programType], (_a = {}, _a["" + styles.menu] = activePanel === 'links', _a)) },
                skipLocation &&
                    React.createElement("a", { className: $(styles.skipLocation, activePanel === 'login' ? 'cb-hidden' : null), "data-cbtrack-linktype": "toggle", href: skipLocation }, "Skip to Content"),
                React.createElement("div", { className: $(apricot4 ? styles.container4 : styles.container, "container") },
                    React.createElement("div", { className: $(styles.items) },
                        React.createElement(WayfindingView, { title: title, programType: programType, activePanel: activePanel, useGlyphLogo: useGlyphLogo, useGlobalNavigation: useGlobalNavigation, togglePanel: this.togglePanel.bind(this), menuRef: this.menu }),
                        (useSearchWidget || useLoginWidget) &&
                            React.createElement(LoginAndSearchControls, { appId: appId, ref: this.controls, activePanel: activePanel, useSearchWidget: useSearchWidget, searchConfig: finalSearchConfig, useLoginWidget: useLoginWidget, togglePanel: this.togglePanel.bind(this), destinationUrl: destinationUrl, authEndpointParam: authEndpointParam, idp: idp }))),
                React.createElement(Panel, { ref: this.navPanel, onOpen: function () {
                        var links = _this.navPanel.current.ref.current.getElementsByTagName('a');
                        links[0].focus();
                    }, isOpen: activePanel === 'links', id: "global-header-navigation", scroll: true, role: "navigation", label: strings.globalNavigation, onTabOut: closePanel, linkGroup: "global-nav" },
                    React.createElement(NavigationLinks, __assign({}, this.props))),
                React.createElement(Panel, { ref: this.loginPanel, onOpen: function () {
                        var input = _this.loginPanel.current.ref.current.getElementsByTagName('input');
                        var links = _this.loginPanel.current.ref.current.getElementsByTagName('a');
                        links[0].focus();
                    }, isOpen: activePanel === 'login', adjustHeight: true, className: styles.identityPanel, scroll: true, role: "dialog", label: strings.loginPanel, onTabOut: focusCloseIcon, linkGroup: "login-panel" },
                    React.createElement(PersonalizedIdentityWidget, __assign({}, finalIdentityConfig))),
                React.createElement(Panel, { onTabOut: focusCloseIcon, isOpen: activePanel === 'search', scroll: false, role: "dialog", label: strings.searchPanel, linkGroup: "search-panel" },
                    React.createElement(GlobalHeaderSearch, __assign({}, finalSearchConfig)))),
            React.createElement(MigrationNotice, null)));
    };
    Header.prototype.componentDidUpdate = function () {
        var _this = this;
        autorun(function () {
            if (!levityStore.isLoggedIn && _this.state.activePanel === 'login') {
                _this.togglePanel('login');
            }
        });
    };
    Header.prototype.componentDidMount = function () {
        var _this = this;
        this.props.tracker.mounted(this.props);
        globalStore.globalHeaderWrapper = this.root.current;
        this.disposer = autorun(function () {
            if (!viewport.isMobile && _this.state.activePanel === 'search')
                _this.togglePanel(null);
        });
        if (this.props.onWidgetMounted) {
            this.props.onWidgetMounted();
        }
    };
    Header.prototype.componentWillUnmount = function () {
        this.props.tracker.unmounted();
        globalStore.globalHeaderWrapper = null;
        this.disposer();
    };
    return Header;
}(Component));
export { Header };
