exports = module.exports = require("../../../widgets/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".cb-widget._3a3sZ3KEosGyR5-17H46R8{background:#d1d1d1;transition:all .4s;height:0;overflow:hidden}.cb-widget._3a3sZ3KEosGyR5-17H46R8 .EEs6tsAJCqu7NauE590qU{padding:24px 0}.cb-widget._3a3sZ3KEosGyR5-17H46R8 .EEs6tsAJCqu7NauE590qU ._3q7WsjSPvEFMhzGpq9J47G{background:#fff;overflow:hidden;transition:all .4s}.cb-widget._3a3sZ3KEosGyR5-17H46R8 .EEs6tsAJCqu7NauE590qU ._3q7WsjSPvEFMhzGpq9J47G ._1Ja3Orc-oLLTJo8GjOksly{display:flex;padding:12px 24px}.cb-widget._3a3sZ3KEosGyR5-17H46R8 .EEs6tsAJCqu7NauE590qU ._3q7WsjSPvEFMhzGpq9J47G ._1Ja3Orc-oLLTJo8GjOksly ._3QpXBiNUrJguP8jncQ_bJL{display:flex}.cb-widget._3a3sZ3KEosGyR5-17H46R8 .EEs6tsAJCqu7NauE590qU ._3q7WsjSPvEFMhzGpq9J47G ._1Ja3Orc-oLLTJo8GjOksly ._3QpXBiNUrJguP8jncQ_bJL._3BGe6EfMXmvhwSF7zuiS7T .cb-red1-color::after{background-color:#c13145}.cb-widget._3a3sZ3KEosGyR5-17H46R8 .EEs6tsAJCqu7NauE590qU ._3q7WsjSPvEFMhzGpq9J47G ._1Ja3Orc-oLLTJo8GjOksly ._3QpXBiNUrJguP8jncQ_bJL._3BGe6EfMXmvhwSF7zuiS7T .cb-green1-color::after{background-color:#3a913f}.cb-widget._3a3sZ3KEosGyR5-17H46R8 .EEs6tsAJCqu7NauE590qU ._3q7WsjSPvEFMhzGpq9J47G ._1Ja3Orc-oLLTJo8GjOksly ._2lc_MuBxi5HslqpLHIg-7c{display:flex;padding:5px 0 0 10px;flex-grow:2}.cb-widget._3a3sZ3KEosGyR5-17H46R8 .EEs6tsAJCqu7NauE590qU ._3q7WsjSPvEFMhzGpq9J47G ._1Ja3Orc-oLLTJo8GjOksly ._2lc_MuBxi5HslqpLHIg-7c p{margin:0}.cb-widget._3a3sZ3KEosGyR5-17H46R8 .EEs6tsAJCqu7NauE590qU ._3q7WsjSPvEFMhzGpq9J47G ._1Ja3Orc-oLLTJo8GjOksly ._39HtEMHxaQBCsrABGe9FyD{display:flex;align-items:start;margin-top:8px}.cb-widget._3a3sZ3KEosGyR5-17H46R8 .EEs6tsAJCqu7NauE590qU ._3q7WsjSPvEFMhzGpq9J47G ._1Ja3Orc-oLLTJo8GjOksly ._39HtEMHxaQBCsrABGe9FyD button{background:none;border:none}", ""]);

// exports
exports.locals = {
	"root": "_3a3sZ3KEosGyR5-17H46R8",
	"notifications": "EEs6tsAJCqu7NauE590qU",
	"notification": "_3q7WsjSPvEFMhzGpq9J47G",
	"viewport": "_1Ja3Orc-oLLTJo8GjOksly",
	"icon": "_3QpXBiNUrJguP8jncQ_bJL",
	"apricot3": "_3BGe6EfMXmvhwSF7zuiS7T",
	"message": "_2lc_MuBxi5HslqpLHIg-7c",
	"control": "_39HtEMHxaQBCsrABGe9FyD"
};